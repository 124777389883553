import Router from "next/router";
import type { GetServerSidePropsContext, GetServerSidePropsResult, NextPage } from "next/types";
import React from "react";

import { BasePage } from "../shared/components/basePage/basePage";
import { Organization } from "../shared/models/organization";
import { AppContext } from "../shared/state/appContext";
import { makeStyles } from "../shared/styles/makeStyles";
import { apiGet } from "../shared/util/api";
import { clearClientPublicToken, clearClientSessionToken } from "../shared/util/cookies";
import { delay } from "../shared/util/delay";
import { getBooleanQueryParamFromContext, getQueryParameterFromContext } from "../shared/util/serverSide";
import { createUrl } from "../shared/util/url";
import { LoginPage } from "../src/loginPage/loginPage";

const useStyles = makeStyles()({
    fillContentContainer: {
        display: "flex",
        flexDirection: "column",
    },
});

interface Props {
    destination?: string;
    ogTitle?: string;
    inviteCode?: string;
    inviteOrganization?: Organization;
    clearSession: boolean;
}

export const getServerSideProps = async (
    context: GetServerSidePropsContext,
): Promise<GetServerSidePropsResult<Props>> => {
    const clearSession = getBooleanQueryParamFromContext(context, "clear_session") === true;
    const destination = getQueryParameterFromContext(context, "d");
    const ogTitle = getQueryParameterFromContext(context, "og_title");
    const inviteCode = getQueryParameterFromContext(context, "invite");

    let inviteOrganization: Organization | undefined;
    if (inviteCode) {
        try {
            inviteOrganization = await apiGet<Organization>(`/org/invite/${inviteCode}`);
        } catch {}
    }

    return {
        props: {
            ...(destination ? { destination } : {}),
            ...(ogTitle ? { ogTitle } : {}),
            ...(inviteCode ? { inviteCode } : {}),
            ...(inviteOrganization ? { inviteOrganization } : {}),
            clearSession,
        },
    };
};

const Page: NextPage<Props> = ({ destination, ogTitle, inviteCode, inviteOrganization, clearSession }) => {
    const { classes } = useStyles();
    const { resetUser } = React.useContext(AppContext);

    React.useEffect(() => {
        if (ogTitle || inviteCode || inviteOrganization || clearSession) {
            Router.replace(
                createUrl("/login", {
                    ...(destination ? { d: destination } : {}),
                    ...(inviteCode && inviteOrganization ? { invite: inviteCode } : {}),
                }),
                undefined,
                {
                    shallow: true,
                },
            );
        }
    }, [destination, ogTitle, inviteCode, inviteOrganization, clearSession]);

    React.useEffect(() => {
        if (clearSession) {
            resetUser();
            delay(500).then(() => {
                clearClientSessionToken();
                clearClientPublicToken();
            });
        }
    }, [clearSession, resetUser]);

    return (
        <BasePage
            className={classes.fillContentContainer}
            pageTitle={`Login - Cymbal Manager`}
            ogTitle={inviteOrganization ? `You're invited to join ${inviteOrganization.name} on Cymbal` : ogTitle}
            ogImage={inviteOrganization?.cover_photo ?? undefined}
            fillContentToHeight
        >
            <LoginPage
                destination={destination}
                organizationInviteInfo={
                    inviteCode && inviteOrganization
                        ? { inviteSecret: inviteCode, organization: inviteOrganization }
                        : undefined
                }
            />
        </BasePage>
    );
};

export default Page;
